import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import styleAutocompletePopover from './utils/style-autocomplete-popover';

import { Loader } from '../loader';
import colors from '../../config/colors';
import { fontSizes } from '../../config/sizes';

const UnStyledAutocompletePopover = ({ className, loading, options, actionView, onOptionClick, displayCascade }) => {
    return <div className={className}>{loading ? <Loader /> : displayOptions(options, actionView, onOptionClick, displayCascade)}</div>;
};

const DisableLi = styled.li`
    pointer-events: none;
    opacity: 0.7;
    color: ${colors.input.label};
    font-size: ${fontSizes.sm};
`;

function DisabledOption({ option }) {
    return (
        <DisableLi aria-label={option.label} onClick={(e) => e.preventDefault()} className='disabled'>
            {option.label}
        </DisableLi>
    );
}
function displayOptions(options, actionView, onOptionClick, displayCascade) {
    return (
        <React.Fragment>
            <ul>
                {options.map((option, index) =>
                    option.value.disabled ? (
                        <DisabledOption key={index} option={option} />
                    ) : (
                        <li key={index} onClick={() => onOptionClick(option)}>
                            {option.label}
                        </li>
                    )
                )}
            </ul>

            {displayCascade && options.length === 0 && displayCascade()}
            {displayCascade && options.length > 0 && actionView && <footer>{actionView}</footer>}

            {!displayCascade && actionView && <footer>{actionView}</footer>}
        </React.Fragment>
    );
}

const StyledAutocompletePopover = styleAutocompletePopover(UnStyledAutocompletePopover);

export const AutocompletePopover = (props) => <StyledAutocompletePopover {...props} />;
AutocompletePopover.propTypes = {
    loading: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    actionView: PropTypes.node,
    onOptionClick: PropTypes.func.isRequired
};
