import styled from 'styled-components';

import colors from '../../../config/colors';
import dimensions from '../../../config/dimensions';
import { fontSizes } from '../../../config/sizes';

export default function (UnStyledField) {
    return styled(UnStyledField)`
        display: ${(props) => getDisplay(props)};
        background: transparent;

        > label:first-child {
            display: ${getLabelDisplay};
            margin-bottom: 0.25rem;
            color: ${getLabelColor};
            font-size: ${fontSizes.sm};
        }

        > textarea {
            resize: none;
        }

        > input[type='text'],
        > input[type='email'],
        > input[type='date'],
        > input[type='number'],
        > input[type='password'],
        > input[type='phone'],
        > textarea {
            display: block;
            width: ${(props) => getWidth(props)};
            padding: 0.525rem 0.85rem;
            border: 1px solid ${getBorderColor};
            border-radius: ${getBorderRadius};
            color: ${(props) => getColor(props)};
            transition: all 200ms ease-out;
            font-size: ${fontSizes.sm};
            min-height: 37px;
            background: ${(props) => getBackgroundColor(props)};

            ::placeholder {
                color: ${colors.input.placeholder};
            }
        }

        > input[type='text']:focus,
        > input[type='email']:focus,
        > input[type='date']:focus,
        > input[type='number']:focus,
        > input[type='password']:focus,
        > input[type='phone']:focus,
        > textarea:focus {
            outline: none;
            border-color: ${(props) => getBorderColor(props, { focused: true })};
            box-shadow: 0 0 0 1px rgba(178, 209, 255, 0.49);
        }

        > input[type='number'] {
            /* Chrome, Safari, Edge, Opera */
            ::-webkit-outer-spin-button,
            ::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            -moz-appearance: textfield;
        }
    `;
}

function getDisplay({ fluid }) {
    return fluid ? 'block' : 'inline-block';
}

function getLabelDisplay({ showLabel }) {
    return showLabel ? 'inline-block' : 'none';
}

function getWidth({ size }) {
    return { sm: '4rem', none: '100%' }[size];
}

function getColor({ disabled }) {
    return disabled ? colors.input.label : colors.black;
}

function getBackgroundColor({ disabled }) {
    return disabled ? 'rgba(215, 215, 215, 0.5)' : colors.white;
}

function getBorderColor(props, { focused = false } = {}) {
    const { borderColor = 'default' } = props.styles;
    if (props.searchIcon) return colors.input.border;
    return focused ? colors.primary : borderColor === 'default' ? colors.input.border : colors[borderColor];
}

function getBorderRadius(props = {}) {
    return props.searchIcon ? '6px 0 0 6px' : dimensions.radius;
}

function getLabelColor({ styles }) {
    const { labelColor = 'default' } = styles;

    return labelColor === 'default' ? colors.input.label : colors[labelColor];
}
