import React, { useState, useEffect, useRef, cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v1 as uuidV1 } from 'uuid';

import colors from '../../config/colors';
import { fontSizes } from '../../config/sizes';
import { joinClassNames } from '../../utils/array';
import { SELECT_TEST_ID } from '../../utils/tests-ids';

import icons from '../../helpers/Icons';
import useOutsideClick from '../../hooks/useOutsideClick';
import { isNonEmptyString, isNullishOrEmpty } from '../../utils';

function UnStyledSelect({ id, className, name, label, placeholder, defaultValue, dataCy, disabled, options, onChange, customOption, shouldIUpdateTheValueOnSelect = () => true }) {
    const ref = useRef(null);
    const [optionsVisible, setOptionsVisible] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState('');
    const placeholderRef = useRef();
    useOutsideClick(ref, () => {
        setOptionsVisible(false);
    });

    useEffect(() => {
        if (options) {
            const foundSelectedLabelOption = options.find(({ label }) => label === selectedLabel);
            if (!foundSelectedLabelOption) setSelectedLabel('');
        }
    }, [options]);

    useEffect(() => {
        if (isNonEmptyString(placeholderRef.current) && isNullishOrEmpty(placeholder)) {
            setSelectedLabel('');
        }
        placeholderRef.current = placeholder;
    }, [placeholder]);

    function onClick(evt, option, { shouldVerifiyNode = false } = {}) {
        if (disabled) return evt.preventDefault();

        const { nodeName } = evt.target;
        if ((shouldVerifiyNode ? nodeName === 'LI' : true) && option) {
            if (shouldIUpdateTheValueOnSelect(option)) {
                setSelectedLabel(option.label);
            }
            if (onChange) onChange({ label, name, value: option.value });
        }
        setOptionsVisible(!optionsVisible);
    }

    return (
        <div ref={ref} data-testid={SELECT_TEST_ID} data-cy={dataCy} className={joinClassNames(className, getClassName(optionsVisible))} id={id} aria-disabled={disabled} onClick={onClick}>
            <header>
                <span>{getDefaultPlaceholder(placeholder, defaultValue, selectedLabel)}</span>
                <img src={icons.expandMore} alt='' />
            </header>
            <section>
                <ul>
                    {options.map((option) => (
                        <Option key={uuidV1()} option={option} onClick={onClick} placeholder={placeholder} selectedLabel={selectedLabel} defaultValue={defaultValue} />
                    ))}
                    {customOption && <li>{customOption}</li>}
                </ul>
            </section>
        </div>
    );
}

function getClassName(optionsVisible) {
    return optionsVisible ? 'options-visible' : 'options-hidden';
}

function getDefaultPlaceholder(placeholder, defaultValue, selectedLabel) {
    return selectedLabel || defaultValue || placeholder;
}

function Option({ option, placeholder, selectedLabel, defaultValue, onClick }) {
    const isSelected = (option.isSelected ? option.isSelected(selectedLabel) : getDefaultPlaceholder(placeholder, defaultValue, selectedLabel) === option.label) ? 'selected' : '';
    const isDisabled = option?.disabled ? 'disabled' : '';

    function handleClick(evt) {
        onClick(evt, option, { shouldVerifiyNode: false });
    }

    return (
        <li aria-label={option.label} onClick={handleClick} className={`${isSelected} ${isDisabled}`}>
            {option.render
                ? cloneElement(option.render({ selected: isSelected }), {
                      onClick: function (evt) {
                          evt.stopPropagation();
                          handleClick(evt);
                      }
                  })
                : option.label}
        </li>
    );
}

const StyledSelect = styled(UnStyledSelect)`
    position: relative;
    display: ${getDisplay};
    width: ${getWidth};
    border: 1px solid ${colors.input.border};
    border-radius: 0.25rem;
    padding: 0;
    cursor: ${getCursor};

    > header {
        height: 100%;
        max-height: 37px;
        border-radius: 0.25rem;
        padding: 0.55rem 0.85rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        background: ${getBackgroundColor};
        max-height: 37.2px;

        > span:nth-child(1) {
            color: ${getDefaultValueColor};
            font-weight: ${getFontWeight};
            font-size: ${fontSizes.sm};
            display: inline-block;
            max-height: 19.19px;
            overflow-y: hidden;
        }

        > span:nth-child(2) {
            color: ${getPlaceholderColor};
            font-size: 1.4rem;
            outline: none;
            font-weight: 500;
        }

        > img {
            display: inline-block;
            height: 1.5rem;
            transition: all 300ms ease-out;
            margin-left: 0.2rem;
            opacity: ${getArrowOpacity};
        }
    }

    &.options-visible {
        border-bottom-color: transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        > header {
            > span:nth-child(2) {
                font-size: 1.4rem;
                transform: rotate(180deg);
                color: ${getPlaceholderColor};
                outline: none;
                font-weight: 500;
            }
        }

        > section {
            display: block;
            z-index: 1;
            position: absolute;
            left: -1px;
            right: -1px;
            top: 100%;
            border: 1px solid ${colors.input.border};
            border-top: none;
            background: ${colors.white};
            max-height: 200px;
            overflow-y: auto;

            ::-webkit-scrollbar {
                width: 0.35rem;
            }
            ::-webkit-scrollbar-track {
                background: ${colors.white};
            }
            ::-webkit-scrollbar-thumb {
                background: ${colors.scrollbar.default};
                border-radius: 10px;
            }
            ::-webkit-scrollbar-thumb:hover {
                background: ${colors.scrollbar.hover};
            }

            > ul {
                list-style: none;
                margin: 0;

                > li {
                    padding: 0.75rem 1rem;
                    color: ${colors.input.label};
                    cursor: pointer;
                    font-size: ${fontSizes.sm};

                    &:hover {
                        background: #f0f3f8;
                    }

                    &.selected {
                        background: #f0f3f8;
                        color: ${colors.black};
                        font-weight: 600;
                    }

                    &.disabled {
                        pointer-events: none;
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    &.options-hidden {
        > section {
            display: none;
        }
    }
`;

function getDisplay({ fluid }) {
    return fluid ? 'block' : 'inline-block';
}

function getWidth({ fluid }) {
    return fluid ? '100%' : 'initial';
}

function getCursor({ disabled }) {
    return disabled ? 'not-allowed' : 'pointer';
}

function getBackgroundColor({ lightBackground, disabled }) {
    if (disabled) return 'rgba(215, 215, 215, 0.5)';
    if (lightBackground) return colors.white;

    return 'transparent';
}

function getDefaultValueColor({ disabled }) {
    return disabled ? colors.input.label : colors.black;
}

function getPlaceholderColor({ disabled, primaryOptionItemColor }) {
    return disabled ? colors.input.label : primaryOptionItemColor ? colors.primary : '#C5C5C5';
}

function getFontWeight({ primaryOptionItemColor }) {
    return primaryOptionItemColor ? 500 : 400;
}

function getArrowOpacity({ options }) {
    return options.length > 1 ? 1 : 0;
}

export default function Select({ options = [], ...restProps }) {
    return <StyledSelect fluid primaryOptionItemColor={false} lightBackground={false} options={options} {...restProps} />;
}
Select.propTypes = {
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fluid: PropTypes.bool,
    primaryOptionItemColor: PropTypes.bool,
    name: PropTypes.string,
    lightBackground: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    customOption: PropTypes.node,
    shouldIUpdateTheValueOnSelect: PropTypes.func
};
