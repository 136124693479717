export default {
    radius: '6px',
    button: {
        padding: {
            normal: '0.538rem 1.25rem',
            medium: '1rem 1rem',
            large: '1.19rem 3rem'
        },
        minHeight: {
            normal: '37.2px',
            medium: '66px',
            large: '108px'
        },
        maxHeight: {
            normal: 'initial',
            medium: 'initial',
            large: '108px'
        },
        boxShadow: {
            normal: 'none',
            medium: '0 0 20px 0 rgba(144, 177, 225, 0.7)',
            large: '0 0 20px 0 #4297A0'
        },
        borderRadius: {
            normal: '3px',
            rounded: '5px',
            circle: '50%'
        }
    },
    actionButton: {
        width: {
            sm: '1.75rem',
            md: '2.25rem'
        },
        height: {
            sm: '1.75rem',
            md: '2.25rem'
        }
    },
    tooltip: {
        width: {
            sm: '15rem',
            md: '20rem',
            lg: '25rem'
        },
        arrowBorderWidth: '10px'
    },
    popover: {
        width: {
            sm: '15rem',
            md: '20rem',
            lg: '25rem'
        }
    },
    card: {
        minHeight: '108px',
        customer: { height: '88px' }
    },
    arrow: {
        width: {
            xs: '0.98rem',
            sm: '1.25rem',
            md: '1.75rem',
            lg: '2.25rem',
            xxl: '3rem'
        },
        height: {
            xs: '0.98rem',
            sm: '1.25rem',
            md: '1.75rem',
            lg: '2.25rem',
            xxl: '3rem'
        }
    },
    sortingArrow: {
        width: {
            xs: '0.3125rem',
            sm: '0.5rem',
            md: '0.75rem',
            lg: '1rem'
        },
        height: {
            xs: '0.25rem',
            sm: '0.375rem',
            md: '0.6rem',
            lg: '0.875rem'
        }
    }
};
