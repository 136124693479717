import React from 'react';
import PropTypes from 'prop-types';

import styleTable from './utils/style-table';
import { actionsPropType, columnsPropType, dataSourcePropType } from './utils/default-prop-types';
import { filterColumns, formatDataSource } from './utils';

import useTable from './hooks/useTable';
import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';
import { TableFooter } from './TableFooter';
import { TableActions } from './TableActions';

function UnStyledTable({
    className,
    columns,
    dataSource,
    onPageChangeCallback,
    onItemsCountPerPageChangeCallback,
    defaultCurrentItemsCountPerPage = 25,
    noDataMessage,
    actions,
    withHeader = true,
    renderFooter,
    onSort,
    onClick,
    shouldComponentHandleItemsNumber = true
}) {
    const { currentItems, currentItemsCountPerPage, pagesCount, currentPage, handlePageChange, handleItemsCountPerPageChange, handleSortAscend, handleSortDesend } = useTable(
        dataSource,
        onPageChangeCallback,
        onItemsCountPerPageChangeCallback,
        shouldComponentHandleItemsNumber,
        defaultCurrentItemsCountPerPage
    );

    const onSortAscend = ({ dataIndex, format }) => (onSort ? onSort(dataIndex, 1) : handleSortAscend({ dataIndex, format }));
    const onSortDescend = ({ dataIndex, format }) => (onSort ? onSort(dataIndex, -1) : handleSortDesend({ dataIndex, format }));

    return (
        <div className={className}>
            {withHeader && (
                <TableHeader
                    totalItemsCount={dataSource.length}
                    actions={actions}
                    onItemsCountPerPageChange={handleItemsCountPerPageChange}
                    defaultCurrentItemsCountPerPage={defaultCurrentItemsCountPerPage}
                />
            )}
            <TableBody columns={filterColumns(columns)} currentItems={currentItems} noDataMessage={noDataMessage} onSortAscend={onSortAscend} onSortDescend={onSortDescend} onClick={onClick} />
            {!renderFooter && currentItems.length > 0 && pagesCount > 1 && (
                <TableFooter
                    currentItemsCountPerPage={currentItemsCountPerPage}
                    currentPage={currentPage}
                    totalItemsCount={dataSource.length}
                    pagesCount={pagesCount}
                    onPageChange={handlePageChange}
                />
            )}
            {renderFooter && renderFooter()}
        </div>
    );
}

const StyledTable = styleTable(UnStyledTable);

export function Table({ columns = [], dataSource = [], shouldComponentHandleItemsNumber = true, ...restProps }) {
    return <StyledTable columns={columns} dataSource={formatDataSource(dataSource)} shouldComponentHandleItemsNumber={shouldComponentHandleItemsNumber} {...restProps} />;
}
Table.propTypes = {
    columns: columnsPropType,
    onSort: PropTypes.func,
    dataSource: dataSourcePropType,
    actions: actionsPropType,
    shouldComponentHandleItemsNumber: PropTypes.bool,
    noDataMessage: PropTypes.string,
    withHeader: PropTypes.bool,
    onClick: PropTypes.func
};
Table.Actions = TableActions;
