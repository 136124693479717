import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckIcon from '../../assets/img/picto_check_circle.svg';
import ErrorIcon from '../../assets/img/picto_error.svg';
import ScheduleIcon from '../../assets/img/schedule_yellow.svg';

import enums from '../../utils/enums';

import Text from '../Text';
import TextBold from '../TextBold';
import TextRow from '../TextRow';
import Body from './Body';

const statusIcons = {
    CONFIRMED: CheckIcon,
    REJECTED: ErrorIcon,
    ARCHIVED: ErrorIcon,
    EXPIRED: ErrorIcon,
    PENDING: ScheduleIcon
};

const UnStyledItemInsuranceHeader = ({ className, insurance }) => {
    return (
        <div className={className}>
            {Object.keys(statusIcons).includes(insurance.status) && <img src={statusIcons[insurance.status]} alt='' />}
            <TextBold>{insurance.name?.toUpperCase()}</TextBold>
        </div>
    );
};

const StyledItemInsuranceHeader = styled(UnStyledItemInsuranceHeader)`
    display: flex;
    align-items: center;

    > img {
        margin-right: 8px;
        height: 16px;
    }
`;

const ItemInsuranceHeader = (props) => <StyledItemInsuranceHeader {...props} />;

ItemInsuranceHeader.propTypes = {
    Insurance: PropTypes.shape({})
};

ItemInsuranceHeader.defaultProps = {
    Insurance: {}
};

const UnStyledItemInsuranceBody = ({ className, insurance }) => {
    return (
        <div className={className}>
            <Body>
                <TextRow>
                    <Text.Label>Statut : </Text.Label>
                    <TextBold>{enums.organizationDocumentStatusTranslations[insurance.status]}</TextBold>
                </TextRow>
                {insurance.reference && (
                    <TextRow>
                        <Text.Label>Numéro du contrat d'assurance : </Text.Label>
                        <TextBold>{insurance.reference.toUpperCase()}</TextBold>
                    </TextRow>
                )}
                {insurance.warrantyArea && (
                    <TextRow>
                        <Text.Label>Couverture de la garantie : </Text.Label>
                        <Text>{enums.warrantyAreas[insurance.warrantyArea]}</Text>
                    </TextRow>
                )}
                <section>
                    <TextRow>
                        <Text.Label>Adresse : </Text.Label>
                        <Text>
                            {insurance.streetNumber} {insurance.streetName} {insurance.postalCode} {insurance.city} {insurance.country}
                        </Text>
                    </TextRow>
                </section>
                {insurance.startDate && (
                    <TextRow>
                        <Text.Label>Date de début d'application : </Text.Label>
                        <Text>{new Date(insurance.startDate).toLocaleDateString()}</Text>
                    </TextRow>
                )}
                {insurance.endDate && (
                    <TextRow>
                        <Text.Label>Date de fin d'application : </Text.Label>
                        <Text>{new Date(insurance.endDate).toLocaleDateString()}</Text>
                    </TextRow>
                )}
            </Body>
        </div>
    );
};

const StyledItemInsuranceBody = styled(UnStyledItemInsuranceBody)`
    > ${Body} {
        > section {
            display: grid;
            grid-auto-flow: column;
            gap: 1rem;

            > ${TextRow} {
            }
        }
    }
`;

const ItemInsuranceBody = (props) => <StyledItemInsuranceBody {...props} />;

ItemInsuranceBody.propTypes = {
    insurance: PropTypes.shape({})
};

ItemInsuranceBody.defaultProps = {
    insurance: {}
};

const ItemInsurance = {
    Header: ItemInsuranceHeader,
    Body: ItemInsuranceBody
};

export default ItemInsurance;
