export default {
    primary: '#102339',
    primaryLight0: '#102339F1',
    primaryLight1: '#102339F1',
    primaryLight2: '#102339F1',
    primaryLight3: '#102339F1',
    primaryDark: '#102339',
    primaryDark0: '#102339',
    primaryDark1: '#102339',
    primaryDark2: '#102339',
    primaryDark3: '#102339',
    primaryOutlineLight: '#10233919',
    secondary: '#4297A0',
    secondaryLight1: '#4297A0F1',
    secondaryLight2: '#4297A0F1',
    secondaryLight3: '#4297A0F1',
    secondaryDark: '#4297A0',
    secondaryDark1: '#4297A0',
    secondaryDark2: '#4297A0',
    secondaryDark3: '#4297A0',
    secondaryOutlineLight: '#4297A019',
    light: '#ffffff',
    lightDark: '#ffffff',
    red: '#ffffff',
    redDark: '#ffffff',
    black: '#000000',
    white: '#ffffff',
    disabled: '#c5c5c5',
    info: '#ffffff',
    warning: '#e2b844',
    error: '#e46526',
    fieldError: '#B7090F',
    input: {
        label: '#74798c',
        placeholder: '#99a3ac',
        border: '#e4e4e4'
    },
    scrollbar: {
        default: '#99a3ac',
        hover: '#b30000'
    },
    popover: {
        item: '#f0f3f8'
    },
    arrow: '#74798C',
    sortingArrow: '#99a3ac',
    table: {
        header: '#F8F8F8'
    },
    housingSituations: {
        classic: '#31aeac',
        precarity: '#0026c8',
        greatPrecarity: '#33f1e6'
    }
};

export const outlines = {
    primaryLight: '#758CDC'
};
